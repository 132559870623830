var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AdminTable',{attrs:{"id":"admin-steam-item","columns":_vm.columns,"title":`Коллекция steamitems`,"model-name":"steamItemModel"},scopedSlots:_vm._u([{key:"top-right",fn:function({ query }){return [_c('ApolloMutation',{attrs:{"tag":"span","mutation":gql => gql`
          mutation updateSteamItemsPrices {
            updateSteamItemsPrices
          }
        `},on:{"error":error =>
          _vm.$q.notify({
            message: error.message,
            multiLine: true,
            type: 'negative'
          }),"done":() => {
          query.refetch();
          _vm.$q.notify({
            message: `Успех`,
            multiLine: true,
            type: 'positive'
          });
        }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('q-btn',{attrs:{"label":"Обновить цены","color":"warning","push":"","size":"md","rounded":"","loading":loading},on:{"click":function($event){return mutate()}}})]}}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }