
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";

@Component({
  components: {
    AdminTable
  }
})
export default class AdminSteamItem extends Vue {
  columns = [
    {
      name: "_id",
      required: true,
      label: "name",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "price",
      required: true,
      label: "price",
      field: "price",
      align: "left",
      type: "number",
      editable: true
    },
    {
      name: "appId",
      required: true,
      label: "appId",
      field: "appId",
      align: "left",
      type: "number"
    },
    {
      name: "moderation",
      align: "center",
      label: "moderation",
      field: "moderation",
      editable: true,
      type: "boolean"
    },
    {
      name: "priceBeforeModeration",
      align: "center",
      label: "priceBeforeModeration",
      field: "priceBeforeModeration",
      editable: false,
      type: "number"
    },
    {
      name: "fixed",
      align: "center",
      label: "fixed",
      field: "fixed",
      editable: true,
      type: "boolean"
    },
    {
      name: "unstable",
      align: "center",
      label: "unstable",
      field: "unstable",
      editable: true,
      type: "boolean"
    },
    {
      name: "unstableOnBuyServer",
      align: "center",
      label: "unstableOnBuyServer",
      field: "unstableOnBuyServer",
      editable: true,
      type: "boolean"
    },
    {
      name: "unstableOnBuyServerByYesterday",
      align: "center",
      label: "unstableOnBuyServerByYesterday",
      field: "unstableOnBuyServerByYesterday",
      editable: true,
      type: "boolean"
    },
    {
      name: "unstableByApi",
      align: "center",
      label: "unstableByApi",
      field: "unstableByApi",
      editable: false,
      type: "boolean"
    },
    {
      name: "banned",
      align: "center",
      label: "banned",
      field: "banned",
      editable: true,
      type: "boolean"
    },
    {
      name: "dailyBanned",
      align: "center",
      label: "dailyBanned",
      field: "dailyBanned",
      editable: true,
      type: "boolean"
    },
    {
      name: "weeklyBanned",
      align: "center",
      label: "weeklyBanned",
      field: "weeklyBanned",
      editable: true,
      type: "boolean"
    },
    {
      name: "monthlyBanned",
      align: "center",
      label: "monthlyBanned",
      field: "monthlyBanned",
      editable: true,
      type: "boolean"
    },
    {
      name: "updatedAt",
      align: "center",
      label: "updatedAt",
      field: "updatedAt",
      editable: true,
      type: "string"
    }
  ];
}
